.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Select-menu-outer {
  z-index: 1000;
}

.table .Select-control {
  height: 32px;
}

.table .Select-placeholder,
.table :not(.Select--multi) > .Select-control .Select-value {
  line-height: 30px;
}

.table .Select-input {
  height: 30px;
}

.table .Select-input > input {
  line-height: 10px;
}

.growl {
  position: fixed;
  top: 15px;
  left: 15px;
  right: 15px;
  z-index: 9999;
}

.growl .alert {
  margin-bottom: 10px;
}

.growl .alert-dismissable .close {
  top: -5px;
  right: -5px;
}

@media (min-width: 768px) {
  .growl {
    left: auto;
    width: 400px;
  }
}

@media print {
  body,
  #site-menubar-unfold,
  #invoice .page-content,
  #invoice .panel-body {
    padding: 0px;
  }
  #invoice {
    padding: 65px 0px;
  }
  #beacon-container,
  #beamerSelector {
    display: none;
  }
}

#beamerSelector {
  right: 100px;
}

/* These are the default badge styles */
.Canny_BadgeContainer .Canny_Badge {
  position: absolute;
  top: -1px;
  right: 13px;
  border-radius: 10px;
  background-color: red;
  border: 1px solid white;
}
