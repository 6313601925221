.category-button-tooltip {
  max-width: 300px;
  text-align: center;
}

.category-tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category-tab-title {
  margin-right: 15px;
}

.category-button-wrapper {
  width: 33.3%;
  padding: 0px 10px 12px;
}

.category-button {
  border-radius: 3px;
  padding: 8px 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-tab-search {
  width: 350px;
}

.category-ban-icon {
  margin-right: 5px;
}

@media screen and (max-width: 480px) {
  .category-tab-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .category-tab-title {
    margin-bottom: 10px;
  }

  .category-button-wrapper {
    width: 50%;
    padding: 0px 5px 12px;
  }

  .category-tab-search {
    width: 100%;
  }
}
