:root {
  --black-1: #182026;
  --gray--1: #f7f7f7;
  --gray--2: #e1e1e1;
  --gray--3: #cfcfcf;
  --gray--4: #b1b1b1;
  --gray--5: #9e9e9e;
  --gray--6: #7e7e7e;
  --gray--7: #626262;
  --gray--8: #515151;
  --gray--9: #3b3b3b;
  --gray--10: #222222;
  --cool-gray--1: #f5f7fa;
  --indigo--1: #ecf4ff;
  --red--3: #db3737;
  --border-radius--1: 2px;
  --border-radius--2: 4px;
  --border-radius--3: 6px;
  --box-shadow--1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --font-size--1: 12px;
  --font-size--2: 14px;
  --font-size--3: 16px;
  --font-size--4: 18px;
  --font-size--5: 20px;
  --font-size--6: 24px;
  --font-size--7: 30px;
  --font-size--8: 36px;
  --font-size--9: 48px;
  --font-size--10: 60px;
  --font-size--11: 72px;
  --line-height--1: 1;
  --line-height--2: 1.25;
  --line-height--3: 1.5;
  --line-height--4: 1.75;
  --line-height--5: 2;
  --spacing--1: 4px;
  --spacing--2: 8px;
  --spacing--3: 12px;
  --spacing--4: 16px;
  --spacing--5: 20px;
  --spacing--6: 24px;
  --spacing--7: 28px;
  --spacing--8: 32px;
  --spacing--9: 48px;
  --spacing--10: 64px;
  --spacing--11: 96px;
  --spacing--12: 128px;
  --spacing--13: 192px;
  --spacing--14: 256px;
  --spacing--15: 384px;
  --spacing--16: 512px;
  --spacing--17: 576px;
  --spacing--18: 768px;
  --container--1: 512px;
  --container--2: 640px;
  --container--3: 768px;
  --container--4: 896px;
  --container--5: 1024px;
  --container--6: 1152px;
  --container--7: 1280px;
  --container--8: 1408px;

  --coolGray--1: #f5f7fa;

  --fontSize--1: 12px;
  --fontSize--2: 14px;
  --fontSize--3: 16px;
  --fontSize--4: 18px;
  --fontSize--5: 20px;
  --fontSize--6: 24px;
  --fontSize--7: 30px;
  --fontSize--8: 36px;
  --fontSize--9: 48px;
  --fontSize--10: 60px;
  --fontSize--11: 72px;

  --lineHeight--1: 1;
  --lineHeight--2: 1.25;
  --lineHeight--3: 1.5;
  --lineHeight--4: 1.75;
  --lineHeight--5: 2;

  --borderRadius--1: 2px;
  --borderRadius--2: 4px;
  --borderRadius--3: 6px;

  --boxShadow--1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.text-center {
  text-align: center;
}

/* Necessary for checkboxes */
.bp3-control {
  margin: 0 !important;
}

.bp3-tab-panel {
  width: 100%;
}

.bp3-breadcrumb {
  white-space: nowrap;
}

.textAlign--center {
  text-align: center !important;
}

.textAlign--right {
  text-align: right !important;
}

.bp3-icon.bp3-intent-success {
  color: #34c759;
}

.bp3-icon.bp3-intent-danger {
  color: #ff443a;
}

@media (max-width: 599px) {
  input {
    font-size: var(--font-size--3) !important;
  }
}
