.support-distributors-body {
  padding: 16px;
  color: #031625;
}

.support-distributors-body > div {
  margin-bottom: 16px;
}

.support-distributors-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.45px;
}

.support-distributors-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.7px;
}

.support-distributors-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.7px;
  color: #304c65;
}

.support-distributors-value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
