.SubscriptionIssuesAlert {
  background-color: #f96197; /* TODO: CSS var */
  color: #fff; /* TODO: CSS var */
  padding: 8px; /* TODO: CSS var */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SubscriptionIssuesAlert p {
  text-align: center;
  margin: 0;
  flex-grow: 1;
}

.SubscriptionIssuesAlert a {
  color: #fff; /* TODO: CSS var */
  font-weight: bold;
  text-decoration: underline;
}

.SubscriptionIssuesAlert a:hover {
  color: #fff; /* TODO: CSS var */
}

.SubscriptionIssuesAlert svg {
  color: #fff; /* TODO: CSS var */
}
