.Text {
  margin-bottom: 0;
}

.Text--normal {
  font-weight: normal;
}

.Text.bp3-intent-danger {
  color: var(--red--3);
}
