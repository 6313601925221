.drawer-close {
  width: 24px;
  height: 24px;
  background: rgba(3, 22, 37, 0.5);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: -35px;
  top: 10px;
}
