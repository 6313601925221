.blue-print-alert-success span.bp3-icon {
  color: #4fc193 !important;
}

.blue-print-alert-danger span.bp3-icon {
  color: #a82a2a !important;
}

.blue-print-alert-warning span.bp3-icon {
  color: #d9822b !important;
}
