.BillingDetailsAlert {
  background-color: #f96197; /* TODO: CSS var */
  color: #fff; /* TODO: CSS var */
  padding: 8px; /* TODO: CSS var */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BillingDetailsAlert p {
  text-align: center;
  margin: 0;
  flex-grow: 1;
}

.BillingDetailsAlert a {
  color: #fff; /* TODO: CSS var */
  font-weight: bold;
  text-decoration: underline;
}

.BillingDetailsAlert a:hover {
  color: #fff; /* TODO: CSS var */
}

.BillingDetailsAlert svg {
  color: #fff; /* TODO: CSS var */
}
