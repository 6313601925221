.CardContent {
  border-bottom: 1px solid var(--gray--2);
}

.CardContent:last-child {
  border-bottom: none;
}

.CardContent--padded {
  padding: var(--spacing--6);
}

.CardContent--fullHeight {
  flex: 1;
}

.CardContent--centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CardContent--autoOverflowX {
  overflow-x: auto;
}
